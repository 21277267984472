<template>
  <div id="earn" class="token-sale p-tb c-l black-bg white-sec">
    <div class="container mt-6">
      <div id="about" class="about-section style-2 p-tb">
        <div class="row align-items-center">
          <div class="col-md-8 about-left align-self-baseline">
            <div class="sec-title"><h1>Poker Battle</h1></div>
            <h5 class="accent-color">Unique event designed for crypto projects and communities.</h5>
            <h5 class="lead">Join us in our next battle and win the big prize!</h5>
            <a class="btn el-btn mt-4" href="https://t.me/ElrosCryptoGroup" target="_blank">Join the battle</a>
          </div>
          <div class="col-md-4 about-left mt-4">
            <!-- <img :src="pokerBattle" alt="team"> -->
          </div>
        </div>
        <!-- FAQ Section start-->
        <div id="faq" class="faq-section p-tb">
          <div class="container">
            <div class="sec-title text-center"><h3>Frequently Asked Questions</h3></div>
            <div class="row">
              <div class="col-sm-12">
                <div class="accordion md-accordion">
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        What is a Poker Battle?
                      </h5>
                    </div>
                    <div class="card-body">
                      The "Poker Battle" is designed for crypto enthusiasts aiming to prove their skills. It involves teams signing up, along with their communities, to engage in a poker competition and win the ultimate prize.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        Who can sign up for this battle?
                      </h5>
                    </div>
                    <div class="card-body">
                      Participation in this battle is open to all crypto projects or crypto lounges. To enter, simply complete the registration by paying the required fee and assembling a team to represent your entity.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How much is the registration fee?
                      </h5>
                    </div>
                    <div class="card-body">
                      The registration fee apply as follows:<br>
                      5 participants - <b>100$</b><br>
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How much is the prize pool?
                      </h5>
                    </div>
                    <div class="card-body">
                      The prize pool will be created from the registration fees contributed by entities interested in joining the battle. For instance, if 10 entities register with 5 participants each, this would result in a prize pool of $1000.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How will the prize be distributed?
                      </h5>
                    </div>
                    <div class="card-body">
                      The distribution of prizes will be as follows: <br>
                      10% will be directed to the Elros Treasury as a commission.<br>
                      40% will be designated for individual player prizes.<br>
                      The victorious entity in the battle will receive 50% of the total prize pool.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How is determined who wins the battle?
                      </h5>
                    </div>
                    <div class="card-body">
                      Points will be received by the finishing position of the individual. Last place gets 1 point and we increase the point by 1 after each elimination. <br>
                      In example if we have 55 participants the scoring system will be the following:<br>
                      <br>
                      55th place gets 1 point<br>
                      54th place gets 2 point<br>
                      ….<br>
                      ….<br>
                      10th place gets 46 points<br>
                      9th place gets 47 points<br>
                      ….<br>
                      3rd place gets 53 points<br>
                      2nd place gets 54 points<br>
                      1st place gets 55 points + 5 bonus<br><br>
                      In case of draw, the player who got the highest position from the teams that are in draw, will receive an extra 1 point. That will decide the winner 🚀
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        What is the frequency of the event's organization?
                      </h5>
                    </div>
                    <div class="card-body">
                      Currently, it's flexible. However, the aim for the future is to ensure it takes place at least once a month.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        How many people signed up previously?
                      </h5>
                    </div>
                    <div class="card-body">
                      <a href="https://www.elroscrypto.com/events" target="_blank">Here</a> you can find all the events that we held.
                    </div>
                  </div>
                  <div class="card">
                    <div id="headingOne1" class="card-header" role="tab">
                      <h5 class="mb-0">
                        Why to participate?
                      </h5>
                    </div>
                    <div class="card-body">
                      📍Increased exposure through our social media channels and the participation of other projects/call groups in the event. As everyone shares the event on their social media platforms.<br>
                      📍Surrounded by reputable projects or call groups that could potentially contribute to your long-term growth.<br>
                      📍Networking opportunities.<br>
                      📍An excellent chance to involve your holders/community, allowing them to play a role in your success.<br>
                      📍Potential for short-term income, which can be utilized for your project or community giveaways.<br>
                      📍Lastly, an opportunity to unwind, have fun, and enjoy a game – a break from work that can be refreshing!<br>
                    </div>
                    <div class="card">
                      <div id="headingOne1" class="card-header" role="tab">
                        <h5 class="mb-0">
                          When is the next event?
                        </h5>
                      </div>
                      <div class="card-body">
                        The upcoming Poker Battle is scheduled for November 17th, starting at 7PM UTC. If you are interested in joining, please don't hesitate to get in touch with <a href="https://t.me/CryptoGemContact" target="_blank">us</a>
                      </div>
                    </div>
                    <div class="card">
                      <div id="headingOne1" class="card-header" role="tab">
                        <h5 class="mb-0">
                          Where to register?
                        </h5>
                      </div>
                      <div class="card-body">
                        In order to play, you need to have a PokerNow account. You can register using this <a href="https://www.pokernow.club/mtt/6th-elros-battle-e_CAU-jn0A" target="_blank">link</a>.
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- FAQ Section end-->
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import pokerBattle from '@/assets/png/5th-battle.png';
  import logo from '@/assets/png/black-gold.png';

  export default Vue.extend({
    name: 'elros-earn',

    data: () => ({
      logo,
      ca: '0x91c6d8B00C1e250963849DffD7B1CBb3342683aF',
      pokerBattle,
    }),
  });
</script>
<style>
.lead {
  color: #ffffff !important;
}

.leaderboard-container {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-radius: 6px;
  color: #bbbbbb;
  cursor: pointer;
  display: table;
  margin-bottom: 30px;
  padding: 30px;
  transition: all 500ms;
}

.leaderboard-container h2 {
  border-bottom: 3px solid #ffffff;
  color: #ffffff;
}

.leaderboard-container li {
  background-color: transparent !important;
  color: #ffffff;
  font-size: 24px;
}

.faq-section {
  background-color: #000000;
}

#earn .card,
#earn .card-header {
  background-color: #000000 !important;
  color: #ffffff !important;
}

#earn .card-header h5 {
  color: #ffffff !important;
}

.align-self-baseline {
  align-self: baseline;
}

.card-body {
  font-size: 18px;
}
</style>
